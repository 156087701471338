import React from 'react'
import { Account } from '../../components/shop/account/Account'
import { graphql, PageProps } from 'gatsby'
import { AccountPageQuery } from '../../graphql/gatsby'

const AccountRoute: React.FC<PageProps<AccountPageQuery>> = ({ data }) => {
  return <Account rewardLevelsImage={data.rewardLevelsImage} />
}

export const query = graphql`
  query AccountPage {
    ...rewardsLevelImage
  }
`

export default AccountRoute
